export  const csvheaders = [
    { label: "Company Name", key: "name" },
    { label: "Logo", key: "logo" },
    { label: "Discount", key: "discount" },
    { label: "status", key: "status" },
    { label: "company", key: "name" },
    { label: "employment identifier", key: "employment_identifier" },
    { label: "Notes", key: "notes" },
    { label: "Password", key: "password" },
    { label: "Contract Expiration Date", key: "contract_expiration_date" },
   
];

export const filterKeys = {
    keys: ['status', 'company', 'registered_date'], //a list of all keys in keyValues array
    keyValues: [
        {
            key: 'status', //key for db 
            label: "Status", //pretty label for site
            values: ['All', 'ACTIVE', 'EXPIRED'] // all filter options
        },
        //repeat for all filters
        {
            key: 'contract_expiration_date',
            label: "Expiration Date",
            values: ['All', 'Today', 'Past 7 days', 'This Month', 'This Year']
        }
    ]
}

export const tableColumns = [
    {
        label: "Select All",
        field: "selectInput",
        sort: "asc",
        width: 50,
    },
    {
        label: 'Company Name',
        field: 'name',
        sort: 'asc',
        width: 50
    },
    {
        label: 'Discount',
        field: 'discount',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Expiration Date',
        field: 'contract_expiration_date',
        sort: 'asc',
        width: 50
    },
    {
        label: 'Status',
        field: 'statusSelect',
        sort: 'asc',
        width: 200
    },
   
    {
        label: 'Actions',
        field: 'actions',
        sort: 'asc',
        width: 50
    }
];