import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getSingleTodo } from "../../services/todo";
import useSWR from "swr";
import { useForm, Controller } from "react-hook-form";
import { updateFaq } from "../../services/faq";
import { Faqs } from "../../interface/faqs";

const EditFaq = () => {
	const { id } = useParams<{ id: string }>();
	const { data } = useSWR<Faqs>(id, getSingleTodo);

	useEffect(() => {
		if (data) {
			reset(data);
		}
	}, [data]);

	const {
		register,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm<Faqs>({
		defaultValues: data,
	});

	const onSubmit = async (payload: Faqs) => {
		payload.id = Number(id);
		await updateFaq(payload);

	};

	return (
		<div>
			Edit Todo
			<form className='form' onSubmit={handleSubmit(onSubmit)}>
				<label htmlFor='question'>
					Question
					<input {...register("question", { required: true })} />
				</label>
				{errors.question && <span>This field is required</span>}
				<label htmlFor='question_es'>
					Question es
					<input {...register("question_es", { required: true })} />
				</label>
				{errors.question_es && <span>This field is required</span>}
				<label htmlFor='answer'>
					Answer
					<input {...register("answer", { required: true })} />
				</label>
				{errors.answer && <span>This field is required</span>}
				<label htmlFor='answer_es'>
					Answer es
					<input {...register("answer_es", { required: true })} />
				</label>
				{errors.answer_es && <span>This field is required</span>}
				<label htmlFor='sort_order'>
					Sort Order
					<input {...register("sort_order", { required: true })} />
				</label>
				{errors.sort_order && <span>This field is required</span>}
				<input type='submit' />
			</form>
		</div>
	);
};

export default EditFaq;
