import http from "./http";
import { Faqs, MyResponse } from "../interface/faqs";
import { mutate } from "swr";

export const postFaq = async (faq: Faqs, data: Faqs[], reset: any) => {
	try {
		const response: MyResponse<any> = await http.post(`/faq/add-faq`, faq);
		if (response.status === 201) {
			faq.id = response.data.insertId;
			const updatedData = [...data, faq];
			mutate("listFaqs", updatedData, false);
			reset();
		}
		return response.data;
	} catch (e) {
		return e;
	}
};

export const getFaqs = async () => {
	try {
		const response = await http.get(`/faq`);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const addFaqs = async (obj: any) => {
	try {
		const response = await http.post('/faq/add-faq', obj)
		return response;
	} catch (e) {
		console.log(e);
	}
};


export const deleteMultipleFaqs = async (data: any, mutate: Function) => {
	try {
		const response = await http.post(`/faq/delete-multiple`, { data: data });
		mutate();
		return response;
	} catch (e) {
		console.log(e);
	}
};

export const getSingleFaq = async (id: number) => {
	try {
		const response = await http.get(`/faq/single-item/${id}`);
		return response.data[0];
	} catch (e) {
		console.log(e);
	}
};

export const updateFaq = async (payload: Faqs) => {
	try {
		const response = await http.put(`/faq/edit-faq`, payload);
		//window.location.reload();
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const updateMultipleStatus = async (data: any, status: string, mutate: Function) => {
	try {
		const response = await http.put(`/faq/update-multiple-status`, { data: data, status: status });
		mutate();
		return response;
	} catch (e) {
		console.log(e);
	}
};
export const deleteFaq = async (id: number, data: Faqs[]) => {
	try {
		// eslint-disable-next-line no-restricted-globals
		if (confirm("Are you sure you want to delete this?") === true) {
			const response = await http.delete(`/faq/delete/${id}`);
			const updatedData = data.filter((item) => item.id !== id);
			mutate("listFaqs", updatedData, false);
		}
	} catch (e) {
		console.log(e);
	}
};
