import React, { useState } from "react";
import "./Registrants.scss";
import { ButtonToolbar, DropdownButton } from 'react-bootstrap';
import { VscCalendar } from 'react-icons/vsc'
import { getRegistrantsByDate } from "../../services/registrants";

const LoadByDate = (props: any) => {
    const [showSpinner, setShowSpinner] = useState(false);
    const [toDate, setToDate] = useState("");
    const [fromDate, setfromDate] = useState("");

    const handleSubmit = async() => {
        
        if(toDate && fromDate){
            let resp = await getRegistrantsByDate({
                to: toDate +' 23:59:59',
                from: fromDate + ' 00:00:00'
            })
            props.mutate(resp, false);
            return;
        }else{
            alert("Please enter both dates");
        }

    };

    return (
        <ButtonToolbar className='emailNotification'>
            <DropdownButton title={<VscCalendar />} id={"dropdown-no-caret"} >
                <div className="m-3">
                <div className="d-block">
                        <label>From:</label>
                        <br />
                        <input type="date" className="w-100" value={fromDate} onChange={(e: any) => { setfromDate(e.target.value) }} />
                    </div>
                    <div className="d-block mt-3">
                        <label>To:</label>
                        <br />
                        <input type="date" className="w-100" value={toDate} onChange={(e: any) => { setToDate(e.target.value) }} />
                    </div>
                </div>
                <button className=' sendButton mt-3 ms-3 me-3' onClick={() => { handleSubmit() }}>Load Data</button>
                <button className="removeFilters w-100 ms-3" onClick={()=>{props.mutate()}}>Reset Data</button>
            </DropdownButton>
        </ButtonToolbar>
    )
}

export default LoadByDate;