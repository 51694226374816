import React, {FC} from 'react'
import "./ContentBlocks.scss";
import { ContentBlockProps } from '../../interface/contentBlockProps';
import { Markup } from 'interweave';




const ContentBlock: FC<ContentBlockProps> = ({title, content,bgColor,color}) => {
  return (
    <div className='contentBlock' style={{'color': color, 'background': bgColor, 'borderRadius': '12px'}}>
      <h2>{title}</h2>
      <Markup content={content} />
    </div>
  )
}

export default ContentBlock;