import Table from 'react-bootstrap/Table';
import { useState, useEffect } from 'react';
import '../Shared/AdminTable.scss';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import TablePagination from '../Shared/TablePagination';

const FAQTable = (props: any) => {
    const [searchInput, setSearchInput] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
   
    const numToDisplay = 20; //number of elements we want to show per page. 
    const numPages = Math.ceil(props.data.rows.length / numToDisplay); //round up the equation for leftover elements.
    
    const handleIndividualSelect = (e: any, obj: any) => {
        e.target.checked ? props.setSelectedArray((selectedArray: any) => [...selectedArray, obj]) : props.setSelectedArray((selectedArray: any) => selectedArray.filter((item: any) => item.id !== obj.id));
    }
    const handleSelectAll = (e: any) => {
        e.target.checked ? props.setSelectedArray([...props.data.rows.filter(searchFilterKey)]) : props.setSelectedArray([]);
    }
    const searchFilterKey = (row: any) => {
        return row.question.toLowerCase().includes(searchInput)
           
    }

    const paginationFilter = (row: any, index:number) => {
        let startIndex =(currentPage-1)*(numToDisplay);
        let endIndex = startIndex + numToDisplay-1;
        return index >= startIndex && index <= endIndex?true:false;
    }

    return (
        <>
            <input type="text" className='searchbar' placeholder="Search.." onChange={(e) => setSearchInput(e.target.value.toLowerCase())} />
            <Table striped className='adminTable'>
                <thead>
                    <tr>
                        {props.data.columns.map((col: any, index: number) => {
                            return (
                                <th>{col.label} {index === 0 && <input className="" type="checkbox" onChange={(e) => { handleSelectAll(e) }} />}</th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {props.data.rows && props.data.rows.filter(paginationFilter).filter(searchFilterKey).map((row: any) => {
                        return (
                            <tr>
                                <td>
                                    <div className="d-flex mt-1 selectIndividual">
                                        <input id={row.id} className='m-auto' type="checkbox" checked={props.selectedArray.filter((e: any) => e.id === row.id).length > 0} onChange={(e) => { handleIndividualSelect(e, row) }} />
                                    </div>
                                </td>
                                <td>{row.question}</td>
                                <td>{row.sort_order}</td>
                                <td className='actionButtons'>
                                    <div className="d-flex actionButtons">
                                        <button onClick={() => { props.handleEditClick(row) }}><FiEdit2 /></button>
                                        <button onClick={() => { props.handleIndividualDelete(row) }}><FiTrash2 /></button>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            
            {props.data.rows &&
            <>
             <span className='showingLabel'>Showing {numToDisplay > props.data.rows.length?props.data.rows.length:numToDisplay} of {props.data.rows.length} items</span>
             <TablePagination tableData={props.data} numPages={numPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </>
               
            }
        </>
    );
}

export default FAQTable;