// @ts-ignore
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style'

// @ts-ignore
const ExportExcel = ({ excelData, filename }) => {

  const filetype = 'application/vnd.openxmlformats-officedocument.spreadsheet.sheet;charset=UTF=8';
  const fileExtension = '.xlsx';



  const exportExcel = async () => {
    const formattedData = await formatData(excelData)
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: filetype });
    FileSaver.saveAs(data, filename + fileExtension);

  }


  //they insisted that the excel document have specific names for fields so I wrote this function instead of changing the database fields and the entire app. 
  const formatData = async (excelData: any) => {
    const formatted_data = new Array<any>();
    excelData.forEach((ele: any) => {
      let splitDate = ele.registered_date.split('T')[0].split('-') 
      formatted_data.push({
        'Company': ele.name,
        'First Name': ele.first_name,
        'Last Name': ele.last_name,
        'Email Address': ele.email,
        'Employment ID': ele.employment_identifier,
        'CA#': ele.ca_number,
        'Check Digit': ele.check_digit,
        'ESID': ele.esid,
        'Account Holder': ele.account_holder,
        'status': ele.status,
        'Register Date': splitDate[1]+'/'+splitDate[2] + '/' +splitDate[0], //this is to match the apparently very specific format VB access needs
        'Last Reminder Date': 'None'
      })
    });

    return formatted_data;
  }

  return (
    <>
      <button className='reportButton secondary me-2' onClick={() => { exportExcel() }} >Create a Report</button>
    </>
  );
};

export default ExportExcel;
