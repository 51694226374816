import http from "./http";


export const getAllItemHistory = async (route:string) => {
	try {
		const response = await http.get(route);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};


