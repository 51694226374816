import React, { useState } from "react";
import "./Home.scss";
import ContentBlocks from "../ContentBlocks/ContentBlocks";
import { useParams } from "react-router-dom";
import Footer from "../Navigation/Footer";
import Terms from "./Terms";
import Customer from "./Customer";
import NotCustomer from "./NotCustomer";
import Faq from "./Faq";

const Home = () => {
    const params = useParams();

    return (
        <div>
            <main>
                <div className="hero">
                    <div className="callout-box">
                        <h1>{params.locale === "es" ? "Programa para Empleados de Clientes de Reliant" : "Reliant Client Employee Program"}</h1>
                        <h4>{params.locale === "es" ? "Inscríbete hoy para tu descuento de empleado" : "Sign up for your employee discount today"}</h4>
                    </div>
                </div>
                <div className="customer-cta flex-lg-row flex-column">
                    <Customer />
                    <div className="vr d-lg-block d-none"></div>
                    <hr className="d-lg-none d-block" />
                    <NotCustomer />
                </div>
                <ContentBlocks page={"login_page"} />
                <Faq />
                <Terms />
            </main>
            <Footer />
        </div>
    );
};

export default Home;
