import ContentBlock from './ContentBlock';
import useSWR from "swr";
import { getContent } from '../../services/ContentBlocks';
import { useParams } from 'react-router-dom';
import { ContentBlockProps } from '../../interface/contentBlockProps';

const ContentBlocks = ({ page }: { page: string }) => {
    const { data, error, isLoading } = useSWR('getContent', getContent);
    const params = useParams();

    const filterKey = (value:ContentBlockProps) => { 
       return value[page as keyof ContentBlockProps] ? true : false;
    };

    if (error) return <div>failed to load</div>;
    if (isLoading) return <div></div>;
    return (
        <div className='contentBlocks'>
            { data.filter(filterKey).map((content: ContentBlockProps, index:any) => {
                //If locale param is spanish content is available, show spanish content, otherwise english
                return params.locale==="es" && content.title_es && content.content_es
                ?<ContentBlock key={index} title={content.title_es} content={content.content_es} bgColor={content.bgColor} color={content.color} />
                :<ContentBlock key={index} title={content.title} content={content.content} bgColor={content.bgColor} color={content.color} />

                
            })}
        </div>
    )
}

export default ContentBlocks

