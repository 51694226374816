
import React, {useContext, useEffect} from "react";
import "./Navbar.scss";
import useSWR from "swr";
import { Nav, Navbar } from "react-bootstrap";
import { Outlet, Link } from "react-router-dom";
import reliantLogo from "../../img/reliant_logo.png";
import { UserContext } from "../../context/UserContext";
import { getUserData } from "../../services/auth";
import { Context } from "vm";


const AdminNavbar = () => {

  const userData:Context = useContext(UserContext);
  const { data } = useSWR("/admins/singleuser/"+userData[0].id, getUserData);

  return (

    <>
      <Navbar className="user-navbar admin" expand="lg">
        <div>
          <Navbar.Brand href="/admin">
            <img src={reliantLogo} alt="Reliant Logo" />
          </Navbar.Brand>
          <span>CEP Administration</span>
        </div>
        <div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navbar-content ml-auto">
              <span className="navGreeting mt-auto mb-auto me-4">Hello, {data?data[0].username: "Admin"}</span>
              {/* Nav.Link is used instead of Link because its an external link. Link is used for internal routing */}
              <Link className={'nav-link'} to="/">Logout</Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <Outlet />
    </>

  );
};

export default AdminNavbar;
