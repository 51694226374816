import http from "./http"

export const getContent = async () => {
	try {
		const response = await http.get(`/contentBlocks/`);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const deleteMultipleContentBlocks = async (data:any, mutate:Function) => {
    try {
		const response = await http.post(`/contentBlocks/delete-multiple`, {data:data});
        mutate();
		return response;
	} catch (e) {
		console.log(e);
	}
};

export const updateMultipleStatus = async (data:any, status:string, mutate:Function) => {
    try {
		const response = await http.put(`/contentBlocks/update-multiple-status`, {data:data, status:status});
		mutate();
		return response;
	} catch (e) {
		console.log(e);
	}
};

export const updateContentBlocks = async (obj:any)=>{
	try {
		const response = await http.put('/contentBlocks/edit-block',obj)
		return response;
	}catch(e){
		console.log(e);
	}
};
export const addContentBlocks = async (obj:any)=>{
	try {
		const response = await http.post('/contentBlocks/add-block',obj)
		return response;
	}catch(e){
		console.log(e);
	}
};


