import React, { useState, useEffect } from "react";
import useSWR from "swr";
import { FiArrowRight } from "react-icons/fi";
import { useForm, SubmitHandler } from "react-hook-form";
import { LoginUser } from "../../interface/admin";
import { LoginCompany } from "../../services/Company";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const Customer = () => {
    const [hide, setHide] = useState(false);
    const [errorText, setErrorText] = useState(false);
    const navigate = useNavigate();
    const params = useParams();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginUser>();

    const onSubmit: SubmitHandler<LoginUser> = async (formData) => {
        let resp: any = await LoginCompany(formData);
        // If you are on spanish route, redirect to spanish register, otherwise english site
        if (resp.status === 401) {
            setErrorText(true);
            setTimeout(() => setErrorText(false), 2000); //resets error text
        } else {
            navigate(params.locale === "es" ? "/es/register" : "/register", { state: resp.data.company });
        }
        return;
    };

    return (
        <div className="col-lg-6 col-12">
            {/** Customer CTA */}
            <div className={"customer " + (hide ? "d-none" : "d-block")}>
                <div>
                    <h2>{params.locale === "es" ? "¿Ya eres cliente?" : "Already a Customer?"}</h2>
                    {/* <p>{params.locale === "es" ? "Le recordaremos inscribirse en Facturación Electrónica cuando termine su solicitud" : " You'll be reminded to set up for Reliant Paperless Billing when you complete your enrollment."}</p> */}
                    <p>{params.locale === "es" ? "Inscríbete para tener acceso a tus descuentos exclusivos con el Programa para Empleados de Clientes. Necesitarás tu número de cuenta de Reliant, un código de compañía único y el número de ESI ID." : "Register to access your exclusive savings with the Client Employee Program. You'll need your Reliant account number, unique company code and ESI ID number.​"}</p>
                    <p>{params.locale === "es" ? "Al completar tu inscripción, te recordaremos inscribirte en Facturación Electrónica." : "You'll be reminded to set up Paperless Billing when you complete your enrollment.​"}</p>
                </div>
                <div className="submit">
                    <button
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            setHide(true);
                        }}
                    >
                        {params.locale === "es" ? "Regístrate" : "Register"}
                    </button>
                </div>
            </div>

            {/** Login Form */}
            <div className={"login noBorder " + (!hide ? "d-none" : "d-block customer")}>
                <h2 className="reliant-pink"> {params.locale === "es" ? "Comenzar Es Fácil" : "Getting Started is Easy"}</h2>
                <p>{params.locale === "es" ? "introduzca su código de empresa" : " Enter your company code."}</p>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <div className="pb-2">
                            <label htmlFor="password"> {params.locale === "es" ? "Codigo de compañia:" : "Company Code:"} </label>
                            <input type="password" id="password" {...register("password", { required: true })} />
                        </div>
                        {/* This is the second div below */}
                        <div className="submit-container">
                            <input type="submit" value={params.locale === "es" ? "Entregar" : "Submit"} />
                            <p>By clicking 'submit', I authorize Reliant to share my enrollment information with the company participating in the Client Employee Program for purposes of validating my enrollment.</p>
                        </div>{" "}
                    </div>
                </form>

                <p className={"errorText " + (errorText ? "d-block" : "d-none")}>*{params.locale === "es" ? "Nombre de empresa y contraseña incorrectos" : "Wrong Company name and password"}*</p>
            </div>
        </div>
    );
};

export default Customer;
