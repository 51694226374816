import { Faqs, FaqProps } from "../../interface/faqs";
import { Link } from "react-router-dom";
import { deleteFaq } from "../../services/faq";
import { useState, useEffect } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import "./Home.scss";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams } from "react-router-dom";
import { Markup } from 'interweave';


const ListFaq = ({ data, error, isLoading }: FaqProps) => {
  const params = useParams();
  return (
    <>
      {data && data.map((faq: Faqs, index: number) => {
        return (
          <>
            <Accordion
              sx={{
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id={`accordionFaqs-${faq.id}`}
              >
                <Typography>
                  <b>
                    {params.locale === "es"
                      ? faq.question_es
                      : faq.question}
                  </b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Markup content=
                   {params.locale === "es"
                  ? faq.answer_es
                  : faq.answer}/></Typography>
              </AccordionDetails>
            </Accordion>
            <div className="lineFaqs"></div>
          </>
        );
      })}</>
  );
};


export default ListFaq;
