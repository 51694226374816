import http from "./http";
import { Todos, MyResponse } from "../interface/todo";
import { mutate } from "swr";

export const postTodo = async (todo: Todos, data: Todos[], reset: any) => {
	try {
		const response: MyResponse<any> = await http.post(`/faq/add-faq`, todo);
		if (response.status === 201) {
			todo.id = response.data.insertId;
			const updatedData = [...data, todo];
			mutate("listTodos", updatedData, false);
			reset();
		}
		return response.data;
	} catch (e) {
		return e;
	}
};

export const getTodos = async () => {
	try {
		const response = await http.get(`/faq`);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const getSingleTodo = async (id: number) => {
	try {
		const response = await http.get(`/faq/single-item/${id}`);
		return response.data[0];
	} catch (e) {
		console.log(e);
	}
};

export const updateTodo = async (payload: Todos) => {
	try {
		const response = await http.put(`/faq/edit-faq`, payload);
		window.location.reload();
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const deleteTodo = async (id: number, data: Todos[]) => {
	try {
		// eslint-disable-next-line no-restricted-globals
		if (confirm("Are you sure you want to delete this?") === true) {
			const response = await http.delete(`/faq/delete/${id}`);
			const updatedData = data.filter((item) => item.id !== id);
			mutate("listTodos", updatedData, false);
		}
	} catch (e) {
		console.log(e);
	}
};
