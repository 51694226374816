export const csvheaders = [
    { label: "Question", key: "question" },
    { label: "Answer", key: "answer" },
    { label: "Question (ES)", key: "question_es" },
    { label: "Answer (ES)", key: "answer_es" },
    { label: "Sort Order", key: "sort_order" }
];

export const tableColumns = [
    {
        label: "Select All",
        field: "selectInput",
        sort: "asc",
        width: 70,
    },
    {
        label: 'Question',
        field: 'question',
        sort: 'asc',
        width: 50
    },
    {
        label: 'Sort Order',
        field: 'sort_order',
        sort: 'asc',
        width: 30
    },
    {
        label: 'Actions',
        field: 'actions',
        sort: 'asc',
        width: 70
    }

];