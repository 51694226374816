import React from "react";
import { useParams } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./Home.scss";
 
const Terms = () => {

  const params = useParams();

  return (
    <>
      <div className="terms">
        <Accordion
          sx={{
            boxShadow: "none",
          }}
        >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{ justifyContent: "left" }}
          
        >
        <Typography>
          {params.locale === "es" ? (
            "Términos & condiciones"
          ) : (
            <Typography>Terms & Conditions</Typography>
          )}
        </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Typography>
          {params.locale === "es"
            ? <p >
              *El descuento de Facturación Electrónica será efectivo dentro de dos ciclos 
              de facturación después de la inscripción, registro en Facturación Electrónica, y 
              verificación de empleo y estatus de cliente. Usted debe estar inscrito en Facturación 
              Electrónica de Reliant para recibir el descuento. El descuento en su factura estará basado 
              en el mes actual de consumo y no aplica a saldos vencidos, impuestos, Reliant Home SolutionsSM, 
              Reliant EcoShareSM, servicios de terceros, cargos no recurrentes o luces de seguridad. El descuento
              de Facturación Electrónica será aplicado a un solo medidor por empleado, antes de que otros descuentos 
              u opciones de facturación sean calculados.
            </p>
            : <p >
              *The Paperless Billing discount will be effective within two billing
              cycles after registration, enrollment in Reliant Paperless Billing, and
              verification of employment and customer status. You must be enrolled in
              Reliant Paperless Billing to receive the discount. The discount will be
              based on the current month's usage and does not apply to past-due
              balances, taxes, third-party services, non-commodity products like Make
              It Solar or Reliant EcoShareSM, non-recurring charges, or guard lights.
              The Paperless Billing discount will be applied to a single meter per
              employee, before other discounts or billing options are calculated.
            </p>}
          {params.locale === "es"
            ? <p >
              **El Programa Reliant Cliente Empleado está disponible para empleados activos de compañías a las cuales Reliant proporciona servicio minorista de electricidad.
              Esta oferta no es transferible y solamente está disponible a clientes residenciales de Texas. Los clientes que se inscriban en el Reliant SmartStart PlanSM no son
                elegibles para el descuento por facturación electrónica.  <br /><br /> Para recibir el descuento por Facturación Electrónica,
              usted debe ser un cliente de Reliant y su cuenta de electricidad de Reliant debe estar a su nombre o mostrar una dirección de servicio que corresponda 
              a la dirección de su vivienda como se refleja en los registros de empleo de su compañía. El descuento por Facturación Electrónica termina inmediatamente si usted se 
              cambia a una dirección de servicio diferente. Es necesario reinscribirse en la nueva dirección de servicio.
            </p>
            : <p >
              *The Reliant Client Employee Program is available for active employees
              of companies to which Reliant provides retail electricity service. This
              offer is not transferrable and is only available to residential
              customers in Texas. Customers signing up on select prepaid electricity
              plans are not eligible for the Paperless Billing discount.<br /><br />To receive
              the Paperless Billing discount, you must be a Reliant customer and your
              Reliant electricity account must be in your name or show a service
              address that matches your home address as reflected in your company’s
              employment records. The Paperless Billing discount ends immediately if
              you move to a different service address. Re-enrollment is required at
              the new service address.
            </p>}
          {params.locale === "es"
            ? <p >
              Su participación en el Reliant Client Employee Program y el Descuento por Facturación Electrónica 
              terminarán inmediatamente con la terminación de su empleo con su compañía o con la terminación del 
              contrato de electricidad de su compañía con Reliant. Reliant se reserva el derecho de descontinuar 
              este programa sin aviso.
            </p>
            : <p >
              Your participation in the Reliant Client Employee Program and the
              Paperless Billing discount will end immediately upon termination of
              employment with your company or upon termination of your company's
              electricity supply contract with Reliant. Reliant reserves the right to
              discontinue this program without notice. The discount cannot be combined with other loyalty discounts.
            </p>}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default Terms;
