import { Faqs, FaqProps } from "../../interface/faqs";
import { Link } from "react-router-dom";
import { deleteTodo } from "../../services/todo";

const ListFaq = ({ data, error, isLoading }: FaqProps) => {
	if (error) return <div>failed to load</div>;
	if (isLoading) return <div>loading...</div>;
	if (data.length === 0)
		return <div className='ms-4'>There are no live tests running</div>;

	return (
		<div className='mt-5'>
			{data.map((faq: Faqs) => {
				return (
					<div key={faq.id}>
						<div className='border'>
							<div>Question is {faq.question}</div>
							<div>Question spanish is {faq.question_es}</div>
							<button>
								<Link to={`/edittodo/${faq.id}`}>Edit</Link>
							</button>
							<button onClick={() => {
								deleteTodo(Number(faq.id), data);
							}}>Delete</button>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default ListFaq;
