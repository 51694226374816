import http from "./http";
import { Emails } from "../interface/emails";


export const getEmailTemplates = async () => {
	try {
		const response = await http.get(`/emails`);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};


export const addEmail = async (obj: any) => {
	try {
		const response = await http.post('/emails/add-email', obj)
		return response;
	} catch (e) {
		console.log(e);
	}
};


export const deleteMultipleEmail = async (data: any, mutate: Function) => {
	try {
		const response = await http.post(`/emails/delete-multiple`, { data: data });
		mutate();
		return response;
	} catch (e) {
		console.log(e);
	}
};


export const updateEmail = async (obj: any) => {
	try {
		const response = await http.put(`/emails/edit-email`, obj);
		return response;
	} catch (e) {
		console.log(e);
	}
};

