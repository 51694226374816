export const csvheaders = [
    { label: "Email Type", key: "email-type" },
    { label: "Email Subject", key: "subject" },
    { label: "Email Body", key: "content" },
    { label: "Email Subject (ES)", key: "subject_es" },
    { label: "Email Body (ES)", key: "content_es" }
];

export const tableColumns = [
    {
        label: "Select All",
        field: "selectInput",
        sort: "asc",
        width: 70,
    },
    {
        label: 'Email Type',
        field: 'email_type',
        sort: 'asc',
        width: 50
    },
    {
        label: 'Email Subject',
        field: 'subject',
        sort: 'asc',
        width: 30
    },
    {
        label: 'Actions',
        field: 'actions',
        sort: 'asc',
        width: 70
    }

];