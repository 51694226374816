import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { FaqProps, Faqs } from "../../interface/faqs";
import "./Faq.scss";
import { postFaq } from "../../services/faq";


const AddFaq = ({ data }: FaqProps) => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<Faqs>();

	const onSubmit: SubmitHandler<Faqs> = async (faq) => {
		await postFaq(faq, data, reset)
	};

	return (
		<div>
			Add FAQ
			<form className='form' onSubmit={handleSubmit(onSubmit)}>
				<label htmlFor='question'>
					Question
					<input {...register("question", { required: true })} />
				</label>
				{errors.question && <span>This field is required</span>}
				<label htmlFor='question_es'>
					Question es
					<input {...register("question_es", { required: true })} />
				</label>
				{errors.question_es && <span>This field is required</span>}
				<label htmlFor='answer'>
					Answer
					<input {...register("answer", { required: true })} />
				</label>
				{errors.answer && <span>This field is required</span>}
				<label htmlFor='answer_es'>
					Answer es
					<input {...register("answer_es", { required: true })} />
				</label>
				{errors.answer_es && <span>This field is required</span>}
				<label htmlFor='sort_order'>
					Sort Order
					<input {...register("sort_order", { required: true })} />
				</label>
				{errors.sort_order && <span>This field is required</span>}
				<input type='submit' />
			</form>
		</div>
	);
};

export default AddFaq;
