import http from "./http";
import { MyResponse } from "../interface/todo";
import { Registrant } from "../interface/registrants";

export const postRegistrant = async (data: Registrant) => {
	try {
		const response: MyResponse<any> = await http.post(`/registrant/add-registrant`, data);
		return response;
	} catch (e:any) {
		return e.response;
	}
};
