import http from "./http";


export const getRegistrants = async () => {
	try {
		const response = await http.get(`/registrant`);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const getRegistrantsByDate = async (data:any) => {
	try {
		const response = await http.post(`/registrant/getRegistrantsByDate`, {data});
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const deleteMultipleRegistrants = async (data: any, mutate: Function) => {
	try {
		const response = await http.post(`/registrant/delete-multiple`, { data: data });
		mutate();
		return response;
	} catch (e) {
		console.log(e);
	}
};

export const updateMultipleStatus = async (data: any, status: string, mutate: Function) => {
	try {
		const response = await http.put(`/registrant/update-multiple-status`, { data: data, status: status });
		mutate();
		return response;
	} catch (e) {
		console.log(e);
	}
};

export const updateRegistrant = async (obj: any) => {
	try {
		const response = await http.put('/registrant/edit-registrant', obj)
		return response;
	} catch (e) {
		console.log(e);
	}
};
export const addRegistrant = async (obj: any) => {
	try {
		const response = await http.post('/registrant/add-registrant', obj)
		console.log(response);
		return response;
	} catch (e) {
		console.log(e);
	}
};

export const sendEmail = async (selectedArray: Array<any>, email_type:string) => {
	try {
		const response = await http.post('/registrant/send-email/'+email_type, selectedArray);
		return response;
	}catch (e){

	}
}
