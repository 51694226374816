export  const csvheaders = [
    { label: "Title", key: "title" },
    { label: "Spanish Title", key: "title_es" },
    { label: "Content", key: "content" },
    { label: "Spanish Content", key: "content_es" },
    { label: "Display Order", key: "sort_order" },
    { label: "Login Page", key: "login_page" },
    { label: "Registration Page", key: "registration_page" },
    { label: "Thankyou Page", key: "thankyou_page" },
    { label: "Color", key: "color" },
    { label: "Background Color", key: "bgColor" },
];

export const tableColumns = [
    {
        label: "Select All",
        field: "selectInput",
        sort: "asc",
        width: 50,
    },
    {
        label: 'Title',
        field: 'title',
        sort: 'asc',
        width: 50
    },
    {
        label: 'Sort Order',
        field: 'sort_order',
        sort: 'asc',
        width: 50
    },
    {
        label: 'Actions',
        field: 'actions',
        sort: 'asc',
        width: 50
    }
];