import { useEffect } from "react";

const RegisterMember = () => {
  useEffect(() => {
    // Redirect to the external URL
    window.location.replace('https://cmp.myreliant.com');
  }, []);

	return <div></div>;
};

export default RegisterMember;
