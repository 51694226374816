import React from "react";
import { Link, useParams } from "react-router-dom";
import { FiEdit3 } from "react-icons/fi";
import Nav from "react-bootstrap/esm/Nav";

const NotCustomer = () => {
    const params = useParams();
    return (
        <div className="not-customer col-lg-6 col-12">
            {/* <div> */}
                <h2>{params.locale === "es" ? "¿No eres cliente?" : "Not a Customer?"}</h2>
                <p>{params.locale === "es" ? "Inscríbete en un plan de electricidad residencial y en Facturación Electrónica de Reliant." : "Sign up for a residential electrical plan and Reliant Paperless Billing."}</p>
                <p>{params.locale === "es" ? "Una vez completes la inscripción, regresa a este sitio web e inscríbete en el programa usando tu número de cuenta bajo “¿Ya eres cliente?”." : " Once you complete your sign-up, please return to this website and enroll in the program under the 'Already a Customer' section using your account number."}</p>
                <div className="submit">
                    {params.locale === "es" ? (
                        <a target="_blank" rel="noreferrer" href="https://shop.reliant.com/es/search-for-plans/">
                            <button>Inscríbete</button>
                        </a>
                    ) : (
                        <a target="_blank" rel="noreferrer" href="https://shop.reliant.com/search-for-plans/">
                            <button>Sign up</button>
                        </a>
                    )}
                </div>
            {/* </div> */}
        </div>
    );
};

export default NotCustomer;
