import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { CardProps } from "../../interface/admin";
import { FiEdit3, FiPlus } from "react-icons/fi";
import "../Admin/Admin.scss";

const Card: FC<CardProps> = ({ title, icon, newRoute, editRoute }) => {
  const navigate = useNavigate();
  const handleRouting = (route: string) => {
    return navigate(route, { state: {} });
  }

  return (
    <div className="content-card col-12 col-lg-6">
      <div className="row p-3 text-center rounded box">
        <div className="card-icon col-1">{icon}</div>
        <div className="card-title col-10 ms-3">{title}</div>
        <div className="col-6 p-3 mt-2">
          {newRoute && <button className="new" onClick={() => { handleRouting(newRoute) }}>
            <FiPlus />
            New
          </button>}
        </div>
        <div className="col-6 p-3 mt-2">
          {editRoute && <button className="edit" onClick={() => { handleRouting(editRoute) }}>
            <FiEdit3 />
            Edit
          </button>}
        </div>
      </div>
    </div>
  );
};

export default Card;
