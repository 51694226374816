import React, { useState, useEffect } from "react";
import useSWR from "swr";
import { getFaqs } from "../../services/faq";
import { Faqs } from "../../interface/faqs";
import { FiTrash2, FiEdit2, FiFilter } from "react-icons/fi";
import { MDBDataTable } from "mdbreact";
import { deleteMultipleFaqs, updateFaq } from "../../services/faq";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { csvheaders, tableColumns } from "./metaData";
import FAQTable from "./FAQTable";
import "./Faqs.scss";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const ListFaqs = () => {
  const { data, mutate } = useSWR("getFaq", getFaqs);
  const [selectedArray, setSelectedArray] = useState<any[]>([]);
  const navigate = useNavigate();
  const tableData = {
    columns: tableColumns,
    rows: data,
  };

  const handleSelectedDelete = () => {
    try {
      if (
        window.confirm(
          "Are you sure you want to delete " + selectedArray.length + " items?"
        )
      ) {
        deleteMultipleFaqs(selectedArray, mutate);
      }
    } catch (e) {
      alert(e);
    }
  };

  const handleIndividualDelete = (obj: any) => {
    try {
      if (window.confirm("Are you sure you want to delete this item?")) {
        //reusing the delete function, just submitting the single object as an array
        deleteMultipleFaqs([obj], mutate);
      }
    } catch (e) {
      alert(e);
    }
  };

  const handleNewClick = () => {
    return navigate("/admin/faqs/edit", { state: {} });
  };
  const handleEditClick = (obj: any) => {
    return navigate("/admin/faqs/edit", { state: obj });
  };

  return (
    <div className="max-width w-100 me-auto ms-auto mt-5">
      <Breadcrumb label={"FAQs"} path={"admin"} />
      <div className="d-flex align-items-baseline">
        <h1 className="me-5">Faqs</h1>
        <div className="d-flex ms-auto">
          <button className="secondary me-2" onClick={handleNewClick}>
            New
          </button>
          <button className="me-5 deleteButton" onClick={handleSelectedDelete}>
            Delete
          </button>
          {selectedArray.length > 0 ? (
            <CSVLink
              className="reportButton secondary me-2"
              data={selectedArray}
              headers={csvheaders}
              filename={"CEP-Faqs.csv"}
            >
              Create a Report
            </CSVLink>
          ) : (
            <button className=" reportButton secondary disabled me-2" disabled>
              Create a Report
            </button>
          )}
        </div>
      </div>

      <div className="table-wrapper">
        {data && (
          <FAQTable
            data={tableData}
            selectedArray={selectedArray}
            setSelectedArray={setSelectedArray}
            handleEditClick={handleEditClick}
            handleIndividualDelete={handleIndividualDelete}
          />
        )}
      </div>
    </div>
  );
};

export default ListFaqs;
