import React, { useState } from "react";
import "./Home.scss";
import useSWR from "swr";
import Accordion from "@mui/material/Accordion";
import { getFaqs } from "../../services/faq";
import "../Faq/Faq.scss";
import ListFaq from "./ListFaq";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams } from "react-router-dom";

const Faq = () => {
  const { data, error, isLoading } = useSWR("listTodos", getFaqs, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false,
  });
  const params = useParams();

  return (
    <>
      <div className="containerFaqs">
        <Typography style={{width: "100%"}}>
          <h2 className="textAlignCenter">
            {params.locale === 'es'?"Preguntas frecuentes" :"FAQs"}
          </h2>
        </Typography>

        <div className="justify-content-left todo">
        <ListFaq data={data} error={error} isLoading={isLoading} />
        </div>
      </div>
    </>
  );
};

export default Faq;
