import React from "react";
import { useLocation, Navigate, useParams } from "react-router-dom";
import "./Register.scss";
import ContentBlocks from "../ContentBlocks/ContentBlocks";
import RegisterForm from "./RegisterForm";
import Footer from "../Navigation/Footer";

const Register = () => {
    const params = useParams();
    const { state } = useLocation(); //this is the state passed in by the redirect, contains token and company info
    if (state && state.id) {
        return (
            <div className="register">
                <main>
                    <div className="hero">
                        <div className="callout-box">
                            {state.logo && <img className="logoImage" src={state.logo} alt="companyLogo" />}
                            <h2 className="mt-2">{state.name}</h2>
                            <h1>Reliant Client Employee Program</h1>
                        </div>
                    </div>
                    <RegisterForm company_id={state.id} company_name={state.name} company_logo={state.logo} />
                    <ContentBlocks page={"registration_page"} />
                </main>
                <Footer />
            </div>
        );
    } else {
        return <Navigate to={params.locale === "es" ? "/es" : "/"} replace={true} />;
    }
};

export default Register;
